import React, {useEffect} from "react";
import {AppContext, AppContextState} from "../context/AppContext";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc)
dayjs.extend(timezone)

interface AppProviderProps {
    children?: React.ReactNode
}

const AppProvider = ({children}: AppProviderProps): JSX.Element => {
    const [dateTimeSelectedStart, setDateTimeSelectedStart] = React.useState<Dayjs | null>(dayjs().startOf('month').subtract(1, 'year'));
    const [dateTimeSelectedEnd, setDateTimeSelectedEnd] = React.useState<Dayjs | null>(dayjs().startOf('day').subtract(1, 'minute'));
    const [clientTimezone, setClientTimezone] = React.useState<string>('Europe/Berlin');
    const [isFilterAvailable, setIsFilterAvailable] = React.useState<boolean>(false);

    useEffect(() => {
        dayjs.tz.setDefault('Europe/Berlin')
        setClientTimezone(dayjs.tz.guess());
    }, [])

    const handleSetDateTimeSelectedStart = (dateTimeStart: Dayjs | null) => {
        setDateTimeSelectedStart(dateTimeStart);

    }
    const handleSetDateTimeSelectedEnd = (dateTimeEnd: Dayjs | null) => {
        setDateTimeSelectedEnd(dateTimeEnd);
    }

    const value: AppContextState = {
        dateTimeSelectedStart: dateTimeSelectedStart,
        dateTimeSelectedEnd: dateTimeSelectedEnd,
        clientTimezone: clientTimezone,
        onSetDateTimeStart: handleSetDateTimeSelectedStart,
        onSetDateTimeEnd: handleSetDateTimeSelectedEnd,
        isFilterAvailable: isFilterAvailable,
        setFilterAvailable: setIsFilterAvailable,
    }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;