import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/system";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";

interface FilterComponentProps {
    buttonSx: SxProps;
}

const STANDARD_START_DATE = dayjs().startOf("month").subtract(1, "year");
const STANDARD_END_DATE = dayjs().endOf("day");

const FilterComponent = (props: FilterComponentProps) => {
    const { t } = useTranslation();
    const { onSetDateTimeStart, onSetDateTimeEnd, dateTimeSelectedStart, dateTimeSelectedEnd } = useAppContext();
    const [valueDateTimeStart, setValueDateTimeStart] = React.useState<Dayjs | null>(dateTimeSelectedStart);
    const [valueDateTimeEnd, setValueDateTimeEnd] = React.useState<Dayjs | null>(dateTimeSelectedEnd);
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
    const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
    const { isFilterAvailable } = useAppContext();
    const theme = useTheme();
    const fullScreen = false;

    useEffect(() => {
        if (localStorage.getItem("use_standard_time") === "true") {
            resetDateTimes();
        } else {
            const localStorageDateTimeStart = localStorage.getItem("cw_cmp_date_from");
            if (localStorageDateTimeStart) {
                const dateTimeStart = dayjs(JSON.parse(localStorageDateTimeStart));
                setValueDateTimeStart(dateTimeStart);
                onSetDateTimeStart(dateTimeStart);
            }

            const localStorageDateTimeEnd = localStorage.getItem("cw_cmp_date_till");
            if (localStorageDateTimeEnd) {
                const dateTimeEnd = dayjs(JSON.parse(localStorageDateTimeEnd));
                setValueDateTimeEnd(dateTimeEnd);
                onSetDateTimeEnd(dateTimeEnd);
            }
        }
    }, []);

    const handleSetDateTimeStart = (dateTime: Dayjs | null) => {
        if (dateTime !== valueDateTimeStart) {
            setValueDateTimeStart(dateTime);
        }
        setButtonDisabled(dateTimeSelectedStart?.toString() === dateTime?.toString() || !dateTime?.isValid());
    };

    const handleSetDateTimeEnd = (dateTime: Dayjs | null) => {
        if (dateTime !== valueDateTimeEnd) {
            setValueDateTimeEnd(dateTime);
        }
        setButtonDisabled(dateTimeSelectedEnd?.toString() === dateTime?.toString() || !dateTime?.isValid());
    };

    const updateDateTimes = () => {
        localStorage.setItem("use_standard_time", "false");

        onSetDateTimeStart(valueDateTimeStart);
        localStorage.setItem("cw_cmp_date_from", JSON.stringify(valueDateTimeStart));

        onSetDateTimeEnd(valueDateTimeEnd);
        localStorage.setItem("cw_cmp_date_till", JSON.stringify(valueDateTimeEnd));
        setButtonDisabled(true);
        enqueueSnackbar(t("snackbar_message_data_will_be_updated"), { variant: "success" });
        handleDialog();
    };

    const handleDialog = () => {
        if (dialogVisible) {
            setDialogVisible(false);
        } else {
            setDialogVisible(true);
        }
    };

    const resetDateTimes = () => {
        localStorage.setItem("use_standard_time", "true");

        onSetDateTimeStart(STANDARD_START_DATE);
        setValueDateTimeStart(STANDARD_START_DATE);
        localStorage.setItem("cw_cmp_date_from", JSON.stringify(STANDARD_START_DATE));

        onSetDateTimeEnd(STANDARD_END_DATE);
        setValueDateTimeEnd(STANDARD_END_DATE);
        localStorage.setItem("cw_cmp_date_till", JSON.stringify(STANDARD_END_DATE));

        if (dialogVisible) {
            enqueueSnackbar(t("snackbar_message_data_will_be_updated"), { variant: "success" });
            handleDialog();
        }
    };

    const getFilterIconButton = () => {
        const button = (
            <IconButton sx={props.buttonSx} onClick={handleDialog} disabled={!isFilterAvailable}>
                <FilterAltIcon />
            </IconButton>
        );

        if (!isFilterAvailable) {
            return (
                <Tooltip title={t("filter_component_filter_disabled_tooltip")}>
                    <span>{button}</span>
                </Tooltip>
            );
        }

        return button;
    };

    const getFilterFormButton = () => {
        const button = (
            <Button disabled={buttonDisabled} onClick={updateDateTimes} variant={"contained"}>
                {t("filter_component_dialog_button_update")}
            </Button>
        );

        if (buttonDisabled) {
            return (
                <Tooltip title={t("filter_component_dialog_button_disabled_info")}>
                    <span>{button}</span>
                </Tooltip>
            );
        }

        return button;
    };

    const getFilterResetButton = () => {
        const button = (
            <Button sx={{ position: "absolute", left: "10px" }} onClick={resetDateTimes} variant={"text"}>
                {t("filter_component_dialog_button_reset")}
            </Button>
        );

        return button;
    };

    const endDateRef: any = React.createRef();

    return (
        <>
            {getFilterIconButton()}
            {dialogVisible && (
                <>
                    <Dialog fullScreen={fullScreen} open={dialogVisible} onClose={handleDialog}>
                        <DialogTitle id="responsive-dialog-title">{t("filter_component_dialog_title")}</DialogTitle>
                        <DialogContent>
                            <DateTimePicker
                                slotProps={{
                                    textField: {
                                        sx: {
                                            width: "100%",
                                            my: 3,
                                            input: { color: theme.palette.text.secondary },
                                        },
                                        onKeyDown: (e) => (e.key === "Enter" ? endDateRef.current.focus() : ""),
                                    },
                                }}
                                maxDateTime={dayjs()}
                                ampm={false}
                                label={t("datetime_picker_label_from")}
                                value={valueDateTimeStart}
                                onChange={handleSetDateTimeStart}
                            />
                            <DateTimePicker
                                slotProps={{
                                    textField: {
                                        sx: {
                                            width: "100%",
                                            my: 3,
                                            input: { color: theme.palette.text.secondary },
                                        },
                                        onKeyDown: (e) => {
                                            if (e.key === "Enter" && !buttonDisabled) {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                updateDateTimes();
                                            }
                                        },
                                    },
                                }}
                                inputRef={endDateRef}
                                ampm={false}
                                label={t("datetime_picker_label_till")}
                                value={valueDateTimeEnd}
                                onChange={handleSetDateTimeEnd}
                            />
                        </DialogContent>
                        <DialogActions>
                            {getFilterResetButton()}
                            <Button onClick={handleDialog} sx={{ marginRight: "25px" }}>
                                {t("filter_component_dialog_button_close")}
                            </Button>
                            {getFilterFormButton()}
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

export { STANDARD_END_DATE, STANDARD_START_DATE };
export default FilterComponent;
