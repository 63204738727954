import React from "react";
import {DialogProps, styled} from "@mui/material";
import Dialog from "@mui/material/Dialog";

const CwDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogTitle-root': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: 0
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minWidth: "300px",
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const DialogComponent = (props: DialogProps) => {
    return (
        <CwDialog {...props}>
            {props.children}
        </CwDialog>
    );
}

export default DialogComponent;