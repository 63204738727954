import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Container, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, styled, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import logo from "../../../images/logo_citywatt.png";
import { NavElement, getMainNavElements, getSettingsMenuNavElements } from "../../helpers/NavigationHelper";
import FilterComponent from "../FilterComponent";
import NotificationIconComponent from "../NotificationIconComponent";

const drawerWidth = "80%";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));

const MobileHeaderComponent = () => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { user, hasPermission } = useAuth();
    const { t } = useTranslation();

    const handleDrawer = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    return (
        <>
            <AppBar
                position="static"
                sx={{
                    borderBottom: "1px solid " + theme.palette.primary.main,
                    boxShadow: "inset 0 -2px 0" + theme.palette.primary.main,
                }}
            >
                <Container>
                    <Toolbar disableGutters>
                        <Box sx={{ marginRight: "20px", flexGrow: 1, display: "flex" }} py={0.9}>
                            <Link to={"/"} style={{ display: "block" }}>
                                <img
                                    src={logo}
                                    className="App-logo"
                                    alt="logo"
                                    style={{ width: "auto", height: "45px", maxHeight: "100%" }}
                                />
                            </Link>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <FilterComponent buttonSx={{}} />
                        </Box>
                        <NotificationIconComponent NotificationIconSX={{marginRight: '25px'}}/>
                        <Box sx={{ flexGrow: 0 }}>
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                onClick={handleDrawer}
                                edge="start"
                                sx={{ zIndex: 1000000 }}
                            >
                                {open ? <CloseIcon /> : <MenuIcon />}
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <div
                        style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        }}
                    >
                        {user?.username}
                    </div>
                </DrawerHeader>
                <Divider color={theme.palette.text.primary} />
                <List
                    sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                    }}
                >
                    {getMainNavElements(hasPermission).map((navElement: any) => {
                        return (
                            <React.Fragment key={"main_nav_" + navElement.translateKeyNavPoint}>
                                <>
                                    <ListItem disablePadding>
                                        <ListItemButton component={Link} to={navElement.path} onClick={closeDrawer}>
                                            <ListItemText primary={t(navElement.translateKeyNavPoint)} />
                                        </ListItemButton>
                                    </ListItem>
                                    {navElement.subEntries &&
                                        navElement.subEntries.map((subEntry: any) => {
                                            return (
                                                <ListItem disablePadding key={"main_nav_" + subEntry.translateKeyNavPoint}>
                                                    <ListItemButton component={Link} to={subEntry.path} onClick={closeDrawer}>
                                                        <ListItemText inset primary={t(subEntry.translateKeyNavPoint)} />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                </>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary={t("nav_settings")} />
                        </ListItemButton>
                    </ListItem>
                    {getSettingsMenuNavElements(hasPermission).map((navElement: NavElement, index: number) => {
                        return (
                            <ListItem disablePadding key={"settings_nav_" + index} onClick={closeDrawer}>
                                <ListItemButton component={Link} to={navElement.path as string}>
                                    <ListItemText inset primary={t(navElement.translateKeyNavPoint)} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Drawer>
        </>
    );
};

export default MobileHeaderComponent;
