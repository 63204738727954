import {Paper, styled} from "@mui/material";
import {PaperProps} from '@mui/material/Paper/Paper';


interface PaperItemComponentProps extends PaperProps {
    textAlign?: 'left' | 'center';
}


export const PaperItemComponent = styled(Paper)<PaperItemComponentProps>(({theme, textAlign = 'center'}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: textAlign,
    color: theme.palette.text.secondary,
}));
