import {createTheme} from "@mui/material";
import {alpha, lighten} from "@mui/system/colorManipulator";
import {colors} from "../theme/Colors";

const defaultMuiTheme = createTheme();

const baseTheme = createTheme({
    typography: {
        fontSize: 16,
        htmlFontSize: 16,
        button: {
            textTransform: "none",
        },
        allVariants: {
            fontFamily: 'CwRegular, Arial',
        },
        h1: {
            marginBottom: defaultMuiTheme.spacing(4),
            fontSize: '2.3rem',
            fontWeight: 'bold',
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    maxWidth: '1536px!important',
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                    fontWeight: '600',
                }
            }
        },
    }
})

const themes = {
    default: createTheme({
        ...baseTheme,
        typography: {
            ...baseTheme.typography,
            h1: {
                ...baseTheme.typography.h1,
                color: colors.defaultTheme.textHeadlines
            }
        },
        palette: {
            ...baseTheme.palette,
            mode: 'light',
            primary: {
                main: colors.defaultTheme.primary,
            },
            secondary: {
                main: colors.defaultTheme.textPrimary,
            },
            action: {
                active: colors.defaultTheme.primary,
            },
            background: {
                default: colors.defaultTheme.bodyBackground,
                paper: colors.defaultTheme.paperBackground,
            },
            text: {
                primary: colors.defaultTheme.textPrimary,
                secondary: colors.defaultTheme.textSecondary,
            },
            customColors: {
                tableBackground: colors.defaultTheme.tableBackground,
                tableRowsBackgroundEven: lighten(colors.defaultTheme.primary, 0.9),
                tableRowsBackgroundOdd: lighten(colors.defaultTheme.primary, 0.99),
                tableHeaderBackgroundColor: colors.defaultTheme.primary,
                tableHeaderTextColor: lighten(colors.defaultTheme.primary, 1),
                contrastTextColor: colors.defaultTheme.contrastText,
            }
        },
        components: {
            ...baseTheme.components,
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.defaultTheme.paperBackground,
                        backgroundImage: "none",
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&.appBarSubMenuItem': {
                            textDecoration: 'none',
                            padding: `${defaultMuiTheme.spacing(2)} ${defaultMuiTheme.spacing(5)}`,
                            borderRadius: 0,
                            "&:hover": {
                                backgroundColor: colors.defaultTheme.primary,
                                color: colors.defaultTheme.contrastText,
                            },
                        },
                    }
                }
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        '&.appBarSubMenuItem a': {
                            color: colors.defaultTheme.textPrimary,
                        }
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    text: {
                        '&:hover': {
                            background: 'none'
                        }
                    },
                    contained: {
                        fontWeight: '600',
                    },
                    outlined: {
                        color: colors.defaultTheme.primary,
                        borderColor: colors.defaultTheme.primary,
                        '&:hover': {
                            color: colors.defaultTheme.primary,
                            borderColor: colors.defaultTheme.primary,
                            backgroundColor: alpha(colors.defaultTheme.primary, 0.15)
                        }
                    }
                },
            },
            MuiCircularProgress: {
                styleOverrides: {
                    root: {
                        color: colors.defaultTheme.primary,
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        padding: '5px 27px',
                        fontSize: '1em',
                        fontWeight: 'normal',
                        marginRight: '15px',
                        borderRadius: '3px',
                        color: colors.defaultTheme.textPrimary,
                        border: `1px solid ${colors.defaultTheme.primary}`,
                        backgroundColor: colors.defaultTheme.contrastText,
                        '&.Mui-selected': {
                            backgroundColor: colors.defaultTheme.primary,
                            color: colors.defaultTheme.contrastText,
                        }
                    }
                }
            },
        }
    }),
    // darkTheme: createTheme({
    //     ...baseTheme,
    //     typography: {
    //         ...baseTheme.typography,
    //         h1: {
    //             ...baseTheme.typography.h1,
    //             color: colors.darkTheme.textHeadlines
    //         }
    //     },
    //     palette: {
    //         ...baseTheme.palette,
    //         mode: 'light',
    //         primary: {
    //             main: colors.darkTheme.primary,
    //         },
    //         secondary: {
    //             main: colors.darkTheme.textPrimary,
    //         },
    //         action: {
    //             active: colors.darkTheme.primary,
    //         },
    //         background: {
    //             default: colors.darkTheme.bodyBackground,
    //             paper: colors.darkTheme.paperBackground,
    //         },
    //         text: {
    //             primary: colors.darkTheme.textPrimary,
    //             secondary: colors.darkTheme.textSecondary,
    //         },
    //         customColors: {
    //             tableBackground: colors.darkTheme.tableBackground,
    //             tableRowsBackgroundEven: lighten(colors.darkTheme.primary, 0.9),
    //             tableRowsBackgroundOdd: lighten(colors.darkTheme.primary, 0.99),
    //             tableHeaderBackgroundColor: colors.darkTheme.primary,
    //             tableHeaderTextColor: lighten(colors.darkTheme.primary, 1),
    //             contrastTextColor: colors.darkTheme.contrastText,
    //         }
    //     },
    //     components: {
    //         ...baseTheme.components,
    //         MuiAppBar: {
    //             styleOverrides: {
    //                 root: {
    //                     backgroundColor: colors.darkTheme.paperBackground,
    //                     backgroundImage: "none",
    //                 }
    //             }
    //         },
    //         MuiMenuItem: {
    //             styleOverrides: {
    //                 root: {
    //                     '&.appBarSubMenuItem': {
    //                         textDecoration: 'none',
    //                         padding: `${defaultMuiTheme.spacing(2)} ${defaultMuiTheme.spacing(5)}`,
    //                         borderRadius: 0,
    //                         "&:hover": {
    //                             backgroundColor: colors.darkTheme.primary,
    //                             color: colors.darkTheme.contrastText,
    //                         },
    //                     },
    //                 }
    //             }
    //         },
    //         MuiList: {
    //             styleOverrides: {
    //                 root: {
    //                     '&.appBarSubMenuItem a': {
    //                         color: colors.darkTheme.textPrimary,
    //                     }
    //                 }
    //             }
    //         },
    //         MuiButton: {
    //             styleOverrides: {
    //                 text: {
    //                     '&:hover': {
    //                         background: 'none'
    //                     }
    //                 },
    //                 contained: {
    //                     fontWeight: '600',
    //                 },
    //                 outlined: {
    //                     color: colors.darkTheme.primary,
    //                     borderColor: colors.darkTheme.primary,
    //                     '&:hover': {
    //                         color: colors.darkTheme.primary,
    //                         borderColor: colors.darkTheme.primary,
    //                         backgroundColor: alpha(colors.darkTheme.primary, 0.15)
    //                     }
    //                 }
    //             },
    //         },
    //         MuiCircularProgress: {
    //             styleOverrides: {
    //                 root: {
    //                     color: colors.darkTheme.primary,
    //                 }
    //             }
    //         },
    //         MuiTab: {
    //             styleOverrides: {
    //                 root: {
    //                     padding: '5px 27px',
    //                     fontSize: '1em',
    //                     fontWeight: 'normal',
    //                     marginRight: '15px',
    //                     borderRadius: '3px',
    //                     color: colors.darkTheme.textPrimary,
    //                     border: `1px solid ${colors.darkTheme.primary}`,
    //                     backgroundColor: colors.darkTheme.contrastText,
    //                     '&.Mui-selected': {
    //                         backgroundColor: colors.darkTheme.primary,
    //                         color: colors.darkTheme.contrastText,
    //                     }
    //                 }
    //             }
    //         },
    //     }
    // }),
};

export default themes