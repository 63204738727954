import {AxiosRequestConfig, InternalAxiosRequestConfig} from "axios";
import {useEffect} from "react";
import {axiosAuthenticated} from "../api/axios";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    customData?: { [key: string]: any };
}

const useAxiosAuthenticated = () => {
    const refresh = useRefreshToken();
    const {accessToken, setIsDone} = useAuth();

    useEffect(() => {
        const requestInterceptor = axiosAuthenticated.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                if (!config.headers["Authorization"]) {
                    config.headers["Authorization"] = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseInterceptor = axiosAuthenticated.interceptors.response.use(
            (response) => response,
            async (error) => {
                const previousRequest = error?.config;

                if(!error?.response)
                    return Promise.reject(error);

                if (error?.response.status === 401 && !previousRequest?.sent) {

                    // User ausloggen, falls er gesperrt wurde
                    if (error.response.data.code === 'user_inactive') {
                        return (window.location.href = "/user/logout");
                    }

                    previousRequest.sent = true;
                    const newAccessToken = await refresh();
                    previousRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

                    return axiosAuthenticated(previousRequest);
                }

                // Wartungsarbeiten
                if (error?.response.status === 503) {
                    window.location.reload();
                    return Promise.reject(error);
                }

                if (error?.config.customData && !("disableRedirectOn403" in (error?.config.customData ?? []))) {
                    if (error?.response.status === 403) {
                        return (window.location.href = "/403");
                    }
                }

                if (error?.response.status === 404) {
                    return (window.location.href = "/404");
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosAuthenticated.interceptors.request.eject(requestInterceptor);
            axiosAuthenticated.interceptors.response.eject(responseInterceptor);
        };
    }, [accessToken, refresh]);

    return axiosAuthenticated;
};

export default useAxiosAuthenticated;
