import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import { SnackbarProvider, closeSnackbar } from "notistack";
import './App.css';
import RoutesConfig from './maintenance-site/RoutesConfig';
import theme from './maintenance-site/common/Theme';

function AppMaintenanceSite() {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme/>
                <SnackbarProvider preventDuplicate maxSnack={3} autoHideDuration={10000} action={(snackbarId) => (
                    <CloseIcon style={{cursor: 'pointer'}} onClick={() => closeSnackbar(snackbarId)} />
                )}>
                    <RoutesConfig/>
                </SnackbarProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default AppMaintenanceSite;
