import axios, {Axios, AxiosResponse} from "axios";
import {ApiResponse} from "./ApiResponse";

export abstract class AbstractApiService {

    /**
     * Axios Instanz
     * @private
     */
    private readonly _axios: Axios;

    constructor() {
        this._axios = axios;
        this.axios.defaults.headers.common = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    get axios(): Axios {
        return this._axios;
    }

    /**
     * convert response to api response type.
     * Needed vor json api promises.
     *
     * @param response
     */
    async factoryApiResponse(response: AxiosResponse): Promise<ApiResponse> {
        return new Promise<ApiResponse>((resolve, reject) => {
            const apiResponse = new ApiResponse(response);
            if (apiResponse.success) {
                resolve(apiResponse);
            } else {
                reject(apiResponse);
            }
        });
    }

}
