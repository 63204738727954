import { Outlet } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import FooterGeneralComponent from "./Footer/FooterGeneralComponent";

const LayoutComponent = () => {
    const theme = useTheme();
    const showMobileHeader = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <HeaderComponent showMobileHeader={showMobileHeader} />
            <Container sx={{ mt: 4, mb: 4, minHeight: "100vh" }}>
                <Outlet />
            </Container>
            <FooterGeneralComponent />
        </>
    );
};

export default LayoutComponent;
