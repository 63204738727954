import {SysUserModel} from "./SysUserModel";
import {SysGroupPermissionModel} from "./SysGroupPermissionModel";

export const GROUP_OVERVIEW_TYPE_DATA = 1
export const GROUP_OVERVIEW_TYPE_VIEW = 2

export type SysGroupModel = {
    id?: number,
    name: string,
    description: string | null,
    type: number,
    sys_users?: SysUserModel[]
    sys_group_permissions?: SysGroupPermissionModel[],
    is_superadmin_group?: boolean,
}