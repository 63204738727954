// Standortzugriff (Liste, Detail, Menüpunkt)
export const VIEW_PERMISSION_LOCATION_ACCESS = '7694f66a-6052-11ee-83f3-0242ac130006'

// Ladestationen mit Karte
export const VIEW_PERMISSION_CHARGING_STATIONS_WITH_MAP = '7694f872-6052-11ee-83f3-0242ac130006'

// Gruppen verwalten + Menüpunkt
export const VIEW_PERMISSION_MANAGE_GROUPS = '7694fa16-6052-11ee-83f3-0242ac130006'

// Zugang verwalten + Menüpunkt
export const VIEW_PERMISSION_MANAGE_LOGINS = '7694fade-6052-11ee-83f3-0242ac130006'

// Zugang verwalten + Menüpunkt
export const VIEW_PERMISSION_MANAGE_VIEW_GROUPS = '7694fb88-6052-11ee-83f3-0242ac130006'

// Diagramm Dashboard: Übersicht der letzten 12 Monate
export const VIEW_PERMISSION_DIAGRAM_OVERVIEW_LAST_12_MONTHS = '7694fd54-6052-11ee-83f3-0242ac130006'

// Diagramm Dashboard: Jahrestendenz
export const VIEW_PERMISSION_DIAGRAM_ANNUAL_TRENDS = '7694ff48-6052-11ee-83f3-0242ac130006'

// Diagramm "Geladene Energie / Anzahl Ladevorgänge pro Monat" auf Standort-Detailseite
export const VIEW_PERMISSION_DIAGRAM_LOCATION_TRACES = '5b549178-7836-4bfe-8a9f-f1f4e1db3722'

// Diagramm "Geladene Energie / Anzahl Ladevorgänge pro Monat" auf Lademedien-Seite
export const VIEW_PERMISSION_DIAGRAM_ID_TAGS_TRACES = '8ad3f052-ca89-45da-bb9d-ce7f91379e42'

// Diagramm Standort: Energieverbrauch & Wirkungsgrad
export const VIEW_PERMISSION_DIAGRAM_LOCATION_EFFICIENCY_DEVELOPMENT = 'a7d6931d-3eaf-4ec7-a438-e0e5fa60bf05'

// Diagramm Lademedien: All Company User Report
export const VIEW_PERMISSION_DIAGRAM_IDENTIFICATION_MEDIA_ALL_COMPANY_USER_REPORT = '5587d821-008c-47b4-8fb6-0a653630e648'

// Export Funktionalität
export const VIEW_PERMISSION_EXPORTS = '01f8f967-64d6-47b8-9bbd-ea82417085ef'

// Diagramm Dashboard: Anteil Ladevorgänge der Lademedien
export const VIEW_PERMISSION_DIAGRAM_PIE_COUNT_CHARGELOGS_ID_TAGS_DASHBOARD = 'd5f3727f-8cd5-4737-8a81-502a28cbadd2'

// Diagramm Dashboard: Anteil geladener Energie der Lademedien
export const VIEW_PERMISSION_DIAGRAM_PIE_COUNT_LOADED_ENERGY_ID_TAGS_DASHBOARD = 'bb06509a-6390-42c5-959a-1cbb82e461fa'

// Diagramm Dashboard: Anteil Nutzungsdauer der Lademedien
export const VIEW_PERMISSION_DIAGRAM_PIE_COUNT_OPERATING_LIFE_ID_TAGS_DASHBOARD = '391f5223-855c-40a2-a733-6d5d9fcc028e'

// Diagramm Lademedien: Ladetechnologie nach geladener Energie
export const VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_WH_IDENTIFICATION_MEDIA = '0f6183ff-f030-4db2-93ff-9c68a56d0974'

// Diagramm Lademedien: Ladetechnologie nach Anzahl Ladevorgänge
export const VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_LV_IDENTIFICATION_MEDIA = '4a8ed138-a9b0-4a94-8f4a-efb0441d91a6'

// Diagramm Lademedien: Anteil Ladevorgänge der Lademedien
export const VIEW_PERMISSION_DIAGRAM_PIE_COUNT_CHARGELOGS_ID_TAGS_IDENTIFICATION_MEDIA = 'b00c4d3d-1aef-4926-a2bc-5c75a2205803'

// Diagramm Lademedien: Anteil geladener Energie der Lademedien
export const VIEW_PERMISSION_DIAGRAM_PIE_COUNT_LOADED_ENERGY_ID_TAGS_IDENTIFICATION_MEDIA = 'e1ffa15d-286e-43d5-877a-650d7df36c67'

// Diagramm Lademedien: Anteil Nutzungsdauer der Lademedien
export const VIEW_PERMISSION_DIAGRAM_PIE_COUNT_OPERATING_LIFE_ID_TAGS_IDENTIFICATION_MEDIA = '6a0fe9e6-b08b-4e88-a67a-a4808da16c2f'

// Standort Detailseite: Technische Angaben anzeigen
export const VIEW_PERMISSION_LOCATION_TECHNICAL_DATA = 'a6a2dca2-4037-4ac0-a7b8-60081653ff45'

// Button Ladevorgang beenden
export const VIEW_PERMISSION_FINISH_CHARGING_OPERATION_BUTTON = '0b06395f-8ae8-4b41-bd8e-12419f099091'

// Lademedien: Menü + Tabelle
export const VIEW_PERMISSION_IDENTIFICATION_MEDIA = '92A02C46-9FF0-4B47-9968-ADC09B58119B'

// Diagramm Dashboard: Anteil Ladevorgänge der letzten 12 Monate
export const VIEW_PERMISSION_DIAGRAM_DASHBOARD_COUNT_CHARGELOGS_LAST_12_MONTHS = '1A4581C2-E346-4A42-AAA9-8093AAEDB2C2'

// Diagramm Dashboard: Anteil geladener Energie der letzten 12 Monate
export const VIEW_PERMISSION_DIAGRAM_DASHBOARD_COUNT_LOADED_ENERGY_LAST_12_MONTHS = '6F7B9F0C-3B59-4314-B1C6-7E4E5E273F2A'

// Diagramm Dashboard: Anteil Nutzungsdauer der letzten 12 Monate
export const VIEW_PERMISSION_DIAGRAM_DASHBOARD_COUNT_OPERATING_LIFE_LAST_12_MONTHS = 'E9BE84CC-1520-4147-987F-6CFBD72A8374'

// Diagramm Infrastruktur-Statistiken: Anzahl Ladevorgänge
export const VIEW_PERMISSION_DIAGRAM_LOCATION_CHARGELOGS = 'C610CCD9-D7A3-4C8B-A902-5E7B91BFA5DF'

// Diagramm Infrastruktur-Statistiken: Geladene Energie
export const VIEW_PERMISSION_DIAGRAM_LOCATION_LOADED_ENERGY = 'D3F95813-44B9-4010-9E5D-B7949FF1C8FC'

// Diagramm Infrastruktur-Statistiken: Umsatz
export const VIEW_PERMISSION_DIAGRAM_LOCATION_REVENUE = '78FB59B4-7643-420D-A5F0-285D643FFDD5'

// Diagramm Dashboard: Static
export const VIEW_PERMISSION_DIAGRAM_DASHBOARD_ABSOLUTE = '2F2A83F2-ED13-4313-BAB3-9A4EF21CE96A'

// Diagramm Dashboard: Live
export const VIEW_PERMISSION_DIAGRAM_DASHBOARD_LIVE = '3145666D-FC81-4AD8-9443-142052AA5C22'

// Plausibilitätscheck Ladevorgänge
export const VIEW_PERMISSION_PLAUSI_CHECK_CHARGELOGS = '4ac4e3fd-1d39-409f-a263-48ac5f5ccced'

// OCPP Einstellungen verwalten
export const VIEW_PERMISSION_OCPP_SETTINGS = 'E9ADCE6E-C393-40BB-A733-97DDA05827A7'

// Admin: Fahrzeugdaten verrwalten
export const VIEW_PERMISSION_ADMIN_MANAGE_VEHICLE_DATA = '41086645-f20a-4830-ad5f-31c89ec27492'

// Fahrzeuginfos verwalten
export const VIEW_PERMISSION_MANAGE_VEHICLE_INFO = 'fcc45d74-e449-492a-bbdb-f536434bbb4c'

// Nutzer besitzt Infrastruktur und darf deswegen Infrastruktur-Statistiken aufrufen
export const VIEW_PERMISSION_INFRASTRUCTURE_STATISTICS_DATA_AVAILABLE = 'ee17e6f3-10c0-4f35-b331-395efc148fdf'

// Nutzer besitzt Infrastruktur und darf deswegen Infrastruktur-Stationen aufrufen
export const VIEW_PERMISSION_INFRASTRUCTURE_CHARGEPOINT_DATA_AVAILABLE = '51268105-d5d3-4d03-ad74-d7587322a70f'

// Nutzer besitzt Infrastruktur und darf deswegen Infrastruktur-Standorte aufrufen
export const VIEW_PERMISSION_INFRASTRUCTURE_LOCATIONS_DATA_AVAILABLE = '6c6eb775-9d86-42ce-aeda-6ecfe974ced1'

// Nutzer besitzt Infrastruktur und darf deswegen Infrastruktur-Stationen aufrufen
export const VIEW_PERMISSION_INFRASTRUCTURE_STATIONS_DATA_AVAILABLE = '51268105-d5d3-4d03-ad74-d7587322a70f'

// Nutzer besitzt Identifikationsmedien und darf deswegen Identifikationsmedien aufrufen
export const VIEW_PERMISSION_IDENTIFICATION_MEDIA_DATA_AVAILABLE = 'd3a7d51e-7226-42a2-a51a-14c6f43088ad'
