import React from "react";
import {ServiceContext} from "../context/ServiceContext";
import {UserService} from "../services/UserService";

interface AuthProviderProps {
    children?: React.ReactNode
}

const ServiceProvider = ({children}: AuthProviderProps): JSX.Element => {

    return (
        <ServiceContext.Provider value={{
            userService: new UserService(),
        }}>
            {children}
        </ServiceContext.Provider>
    );
};

export default ServiceProvider;