import React from 'react';
import useAuth from "../../../hooks/useAuth";

type UserImageComponentProps = {
    maxWidth: string,
}

const UserImageComponent = (props: UserImageComponentProps) => {
    const {user} = useAuth();

    return (<>
        {user?.userLogo && (
            <img alt={user?.username}
                 style={{maxWidth: props.maxWidth}}
                 src={(user) ? '/img/user/' + user.userLogo : ''}
            />
        )}
    </>)

}

export default UserImageComponent;