import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import * as de from "./translations/de.json";
import * as en from "./translations/en.json";
import * as it from "./translations/it.json";

const i18nQrc = i18n.createInstance();

i18nQrc
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        debug: process.env.NODE_ENV === "development",
        fallbackLng: "de",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            de: { translation: de },
            en: { translation: en },
            it: { translation: it },
        },
        supportedLngs: [
            "de",
            "en",
            "it",
        ],
    });

export default i18nQrc;
