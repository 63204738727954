import { Dispatch, SetStateAction, createContext } from "react";

export type User = {
    user: string,
    userId: string,
    userLogo?: string,
    username: string,
    rights: string[],
    permissions: string[],
    language: string|null,
}

export type AuthContextState = {
    accessToken: string | null,
    user: User | null,
    rights: string[],
    onLogin: (user: User, accessToken: string) => void,
    onLogout: () => boolean | void,
    setAccessToken: (prev: any) => void,
    setUser: (prev: User) => void,
    setRights: (prev: string[]) => void,
    hasRight: (requiredRight: string) => boolean,
    hasPermission: (requiredPermission: string) => boolean,
    setPermissions: (prev: string[]) => void,
    isDone: boolean,
    setIsDone: (prev: boolean) => void,
    unreadNotificationsAmount: number,
    setUnreadNotificationsAmount: Dispatch<SetStateAction<number>>
};

const AuthContext = createContext<AuthContextState>({
    accessToken: null,
    user: null,
    rights: [],
    onLogin: () => {
    },
    onLogout: () => {
    },
    setAccessToken: () => {
    },
    setUser: () => {
    },
    setRights: () => {
    },
    hasRight: requiredRight => {
        return false;
    },
    setPermissions: () => {
    },
    hasPermission: requiredPermission => {
        return false;
    },
    isDone: false,
    setIsDone: () => {
    },
    unreadNotificationsAmount: 0,
    setUnreadNotificationsAmount: () => {
    },

});


export {AuthContext};


