import React from "react"
import { Outlet } from "react-router-dom"
import { useTheme } from "@mui/material"

const LayoutLoggedOutComponent = () => {

    const theme = useTheme()

    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundImage: "url('/img/login/bg.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundAttachment: "fixed",
                    zIndex: -1
                }}
            ></div>
            <Outlet />
        </>
    )
}

export default LayoutLoggedOutComponent
