import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import AppQrCodeCharging from "./AppQrCodeCharging";
import i18nCmp from "./common/I18n";
import i18nQrc from "./qr-code-charging/common/I18n";
import i18nMns from "./maintenance-site/common/I18n";
import reportWebVitals from "./reportWebVitals";
import AppMaintenanceSite from "./AppMaintenanceSite";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

switch (process.env.REACT_APP_BUILD_TARGET) {
    case "qr_code_charging":
        root.render(
            <I18nextProvider i18n={i18nQrc}>
                <AppQrCodeCharging />
            </I18nextProvider>
        );
        break;
    case "maintenance_site":
        root.render(
            <I18nextProvider i18n={i18nMns}>
                <AppMaintenanceSite />
            </I18nextProvider>
        );
        break;
    default:
        root.render(
            <I18nextProvider i18n={i18nCmp}>
                <App />
            </I18nextProvider>
        );
        break;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
