import {AbstractApiService} from "./AbstractApiService";
import {AxiosResponse} from "axios";
import {ApiResponse} from "./ApiResponse";
import {apiUrl} from "./ApiUrl";
import {User} from "../context/AuthContext";

export class UserService extends AbstractApiService {

    async login(email: string, password: string) {

        return new Promise<ApiResponse>((resolve, reject) => {
            this.axios.post(
                apiUrl() + '/user/login',
                {
                    cred_usr: email,
                    password: password,
                },
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                })
                .then((response: AxiosResponse) => this.factoryApiResponse(response))
                .then((apiResponse: ApiResponse) => {
                    resolve(apiResponse);
                })
                .catch((error: ApiResponse) => {
                    reject(error);
                });
        });
    }

    async logout() {
        return new Promise<ApiResponse>((resolve, reject) => {
            this.axios
                .post(
                    '/api/user/logout',
                    {},
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.getJwtToken(),
                        }
                    }
                )
                .then((response: AxiosResponse) => this.factoryApiResponse(response))
                .then((apiResponse: ApiResponse) => {
                    this.deleteJwtToken();
                    resolve(apiResponse);
                })
                .catch((error: ApiResponse) => {
                    reject(error);
                });
        });
    }

    addUserToLocalstorage(user: User) {
        localStorage.setItem("user", JSON.stringify(user))
    }

    getUserFromLocalstorage() {
        return JSON.parse(localStorage.getItem("user") as string);
    }

    deleteUserFromLocalstorage() {
        localStorage.removeItem("user");
    }

    getJwtToken() {
        return localStorage.getItem("jwt");
    }

    setJwtToken(token: string) {
        localStorage.setItem("jwt", token)
    }

    deleteJwtToken() {
        localStorage.removeItem("jwt");
    }

    getRefreshToken() {
        return localStorage.getItem("refreshToken")
    }

    setRefreshToken(token: string) {
        localStorage.setItem("refreshToken", token)
    }

    isUserLoggedIn() {
        return this.getJwtToken() !== null;
    }
}
