import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

interface ProtectedRouteProps {
    requireOnePermission?: string[] | string | undefined,
    requireAllPermissions?: string[] | undefined
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const navigate = useNavigate();
    const { accessToken, hasPermission, isDone } = useAuth();
    const location = useLocation();
    let redirectTo = "/";

    const excludedPaths = ["/user/login", "/user/logout"];

    if (!excludedPaths.includes(location.pathname)) {
        redirectTo = location.pathname;
    }

    useEffect(() => {
        if (isDone && accessToken) {
            if (props.requireAllPermissions) {
                // Fall: Nutzer benötigt alle Berechtigungen
                // Prüfe für jede einzelne Berechtigung, ob der Nutzer sie hat, ansonsten leite auf 403-Seite weiter
                for (const permission of props.requireAllPermissions) {
                    if (!hasPermission(permission)) {
                        navigate("/403");
                        break;
                    }
                }
            }
            // Fall: Nutzer benötigt nur eine Berechtigung
            else if (props.requireOnePermission) {
                // Fall: Berechtigungen werden als Array übergeben
                const permissionAsArray = Array.isArray(props.requireOnePermission)
                    ? props.requireOnePermission
                    : [props.requireOnePermission];

                if (permissionAsArray.every((item) => typeof item === "string")) {
                    // Prüfe für jede einzelne Berechtigung, ob der Nutzer sie hat, wenn er keine einzige hat leite auf 403-Seite weiter
                    const hasOnePermission = permissionAsArray.some(hasPermission);
                    // Prüfe durch Flag, ob mindestens eine Berechtigung vorhanden war
                    if (!hasOnePermission) {
                        navigate("/403");
                    }
                }
            }
        }
    }, [isDone]);

    if (!isDone) {
        return <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
            }}
        >
            <CircularProgress />
        </Box>;
    } else {
        return <>{accessToken ? <Outlet /> : <Navigate to="/user/login" state={{ redirectTo: redirectTo }} />}</>;
    }
};

export default ProtectedRoute;
