import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import themes from "./common/Theme";
import {ThemeProvider} from "@mui/material";
import AuthProvider from "./providers/AuthProvider";
import RoutesConfig from "./common/RoutesConfig";
import ServiceProvider from "./providers/ServiceProvider";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import AppProvider from "./providers/AppProvider";
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import {library} from "@fortawesome/fontawesome-svg-core";
import {faPlug, faPlugCircleBolt, faBolt, faEuroSign, faChargingStation} from "@fortawesome/free-solid-svg-icons";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from "dayjs";
import {SnackbarProvider, closeSnackbar} from "notistack";
import CloseIcon from '@mui/icons-material/Close';


dayjs.extend(utc)
dayjs.extend(timezone)

// FontAwesomeIcon muss hier eingebungen werden
library.add(faPlug, faPlugCircleBolt, faBolt, faEuroSign, faChargingStation);

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
            <AppProvider>
                <ThemeProvider theme={themes['default']}>
                    <CssBaseline enableColorScheme/>
                    <AuthProvider>
                        <ServiceProvider>
                            <SnackbarProvider preventDuplicate maxSnack={3} autoHideDuration={10000} action={(snackbarId) => (
                                <CloseIcon style={{cursor: 'pointer'}} onClick={() => closeSnackbar(snackbarId)} />
                            )}>
                                <RoutesConfig/>
                            </SnackbarProvider>
                        </ServiceProvider>
                    </AuthProvider>
                </ThemeProvider>
            </AppProvider>
        </LocalizationProvider>
    );
}

export default App;
