import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

const PersistLogin = () => {

    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { isDone } = useAuth();

    useEffect(() => {

        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {

            } finally {
                isMounted && setIsLoading(false);
            }
        };


        isDone ? verifyRefreshToken() : setIsLoading(false);

        return () => {
            isMounted = false;
        };

    }, []);

    return (
        <>
            {isLoading ?
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "80vh", // Füllt die gesamte Höhe des Viewports
                    }}
                >
                    <CircularProgress />
                </Box>
                :
                <Outlet />
            }
        </>
    );

};

export default PersistLogin;
