import { Badge, IconButton, SxProps, useTheme } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

interface NotificationIconComponentProps {
    NotificationIconSX: SxProps | undefined;
};

const NotificationIconComponent = (props: NotificationIconComponentProps) => {
    const { unreadNotificationsAmount } = useAuth();
    const theme = useTheme();

    return (
        <Link to={"/benachrichtigungen"}>
            <IconButton sx={props.NotificationIconSX}>
                <Badge
                    badgeContent={unreadNotificationsAmount}
                    color="primary"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    {unreadNotificationsAmount > 0 
                    ? ( <NotificationsActiveIcon sx={{ color: theme.palette.primary.main }} /> )
                    : ( <NotificationsIcon sx={{ color: theme.palette.secondary.main }} /> )}
                </Badge>
            </IconButton>
        </Link>
    );
};

export default NotificationIconComponent;
