export const colors = {
    defaultTheme: {
        bodyBackground: '#f8f8fe',
        primary: '#4b9bd5',
        textPrimary: '#7b7c7e',
        textSecondary: '#7b7c7e',
        textHeadlines: '#7b7c7e',
        tableBackground: '#ffffff',
        paperBackground: '#ffffff',
        contrastText: '#ffffff',
    },
    // darkTheme: {
    //     bodyBackground: '#383e45',
    //     primary: '#4b9bd5',
    //     textPrimary: '#ffffff',
    //     textHeadlines: 'rgb(123, 124, 126)',
    //     textSecondary: '#9B9E9F',
    //     tableBackground: '#ffffff',
    //     paperBackground: '#ffffff',
    //     contrastText: '#383e45',
    // },
}