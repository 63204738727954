import React, {useContext} from "react";
import {Dayjs} from "dayjs";

export type AppContextState = {
    dateTimeSelectedStart: Dayjs | null,
    dateTimeSelectedEnd: Dayjs | null,
    clientTimezone: string,
    onSetDateTimeStart: (dateTime: Dayjs | null) => void,
    onSetDateTimeEnd: (dateTime: Dayjs | null) => void,
    isFilterAvailable: boolean,
    setFilterAvailable: (isAvailable: boolean) => void,
};

const AppContext = React.createContext<AppContextState>({
    dateTimeSelectedStart: null,
    dateTimeSelectedEnd: null,
    clientTimezone: 'Europe/Berlin',
    onSetDateTimeStart: () => {
    },
    onSetDateTimeEnd: () => {
    },
    isFilterAvailable: true,
    setFilterAvailable: () => {
    }
});


const useAppContext = () => useContext(AppContext)

export {useAppContext, AppContext}