import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const {accessToken, setAccessToken} = useAuth();

    return async () => {
        const result = await axios.post('/user/refresh',
            null,
            {
                withCredentials: true
            }
        )    
        .then((response) => {
            setAccessToken(response.data.access);
            return response.data.access;
        })
        .catch((error) => {
            if (error?.response.status === 401) {
                if (accessToken){
                    setAccessToken(null)
                    return (window.location.href = "/user/login");
                }
            }
            
            return null;
        });

        return result;
    };
};

export default useRefreshToken;