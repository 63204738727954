import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL ?? "http://localhost/cmp";

const axiosUnauthenticated = axios.create({
    baseURL: BASE_URL,
});

axiosUnauthenticated.interceptors.response.use(
    (response) => response,
    async (error) => {
        // Wartungsarbeiten
        if (error?.response?.status === 503) {
            window.location.reload();
        }

        return Promise.reject(error);
    }
);

export default axiosUnauthenticated;

export const axiosAuthenticated = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});
