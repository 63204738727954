import React, {useEffect, useState} from 'react';
import {FormControl, Grid, InputLabel, Select} from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import useAxiosAuthenticated from "../../hooks/useAxiosAuthenticated";

export interface SysGroupFormValues {
    name: string,
    description: string|null,
    type: number,
    prevState: null;
}

interface ValidGroupTypes {
    id: number,
    name: string
}

interface GroupEditFormComponentProps {
    defaultValue?: SysGroupFormValues,
    inputsRequired: boolean,
    onChangeHandlerGroupName: Function,
    onChangeHandlerGroupDescription: Function,
    onChangeHandlerGroupType: Function,
    errorGroupName: string | null,
    errorGroupDescription: string | null,
    errorGroupType: string | null,
    setGeneralError: Function,
    urlBase: string,
    type: number,
}

export default function GroupEditFormComponent(props: GroupEditFormComponentProps) {

    const {t} = useTranslation();
    const [groupName, setGroupName] = useState<string>('');
    const [groupDescription, setGroupDescription] = useState<string|null>('');
    const [groupType, setGroupType] = useState<number | string>('');
    const [validGroupTypes, setValidGroupTypes] = useState<ValidGroupTypes[]>([]);
    const axiosAuthenticated = useAxiosAuthenticated();

    useEffect(() => {

        if (props.defaultValue) {
            setGroupName(props.defaultValue.name)
            setGroupDescription(props.defaultValue.description)
            setGroupType(props.defaultValue.type)
        }

    }, [
        props.defaultValue,
    ]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getData = async () => {
            try {
                const response = await axiosAuthenticated.get(props.urlBase + '-types', {
                    signal: controller.signal
                });

                isMounted && setValidGroupTypes(response.data);
            } catch (err) {
                props.setGeneralError()
            }
        }

        void getData();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [
        axiosAuthenticated
    ]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={8}>
                <TextField
                    helperText={props.errorGroupName ? props.errorGroupName : null}
                    error={(props.errorGroupName) ? true : undefined}
                    required={props.inputsRequired}
                    fullWidth
                    name="group_name"
                    label={t('admin_user_edit_form_new_group_label')}
                    id="group_name"
                    type={'text'}
                    onChange={e => {
                        const value = e.target.value;
                        props.onChangeHandlerGroupName(value);
                        setGroupName(value);
                    }}
                    value={groupName}
                    inputProps={{maxLength: 100}}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel required={props.inputsRequired}
                                id="label-for-group-type">
                        {t('admin_user_edit_form_new_group_type_label')}
                    </InputLabel>
                    <Select
                        required={props.inputsRequired}
                        labelId="label-for-group-type"
                        value={groupType}
                        label={t('admin_user_edit_form_new_group_type_label')}
                        onChange={e => {
                            const value = e.target.value;
                            props.onChangeHandlerGroupType(value as number);
                            setGroupType(value as number);
                        }}
                        fullWidth={true}
                        defaultValue={(props.defaultValue?.type)}
                    >
                        {validGroupTypes?.map((data: ValidGroupTypes, key) => {
                            return (
                                <MenuItem value={data.id} key={key}>{data.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    helperText={props.errorGroupDescription ? props.errorGroupDescription : null}
                    error={(props.errorGroupDescription) ? true : undefined}
                    required={false}
                    fullWidth
                    name="group_description"
                    label={t('admin_user_edit_form_group_description_label')}
                    id="group_description"
                    type={'text'}
                    onChange={e => {
                        const value = e.target.value;
                        props.onChangeHandlerGroupDescription(value);
                        setGroupDescription(value);
                    }}
                    value={groupDescription}
                    inputProps={{maxLength: 300}}
                />
            </Grid>
        </Grid>
    )
}