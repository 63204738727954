import React from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import logo from "../../../images/logo_citywatt.png";
import FilterComponent from "../FilterComponent";
import Button from "@mui/material/Button";
import PopupState, { bindFocus, bindHover, bindMenu } from "material-ui-popup-state";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import UserImageComponent from "./UserImageComponent";
import { getMainNavElements, getSettingsMenuNavElements, NavElement } from "../../helpers/NavigationHelper";
import DownloadIcon from "@mui/icons-material/Download";
import { useTheme } from "@mui/material/styles";
import NotificationIconComponent from "../NotificationIconComponent";


const DesktopHeaderComponent = () => {
    const { t } = useTranslation();
    const { user, hasPermission } = useAuth();
    const theme = useTheme();

    const getNavButtonSx = (forProfileMenu = false, forFilterButton = false): SxProps => {
        const hover = {
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
        };

        const base = {
            fontSize: "95%",
            py: 2.3,
            px: 3,
            color: theme.palette.text.primary,
            borderRadius: 0,
        };

        if (forFilterButton) {
            const filterButtonSx = {
                px: 2,
            };

            return {
                ...base,
                ...filterButtonSx,
                ...hover,
            };
        }

        if (!forProfileMenu) {
            const mainNavSx = {
                "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                },
            };

            return {
                ...base,
                ...mainNavSx,
                ...hover,
            };
        }

        return base;
    };

    const submenuSx = {
        paper: {
            color: theme.palette.primary.main,
            padding: 0,
            borderRadius: 0,
        },
    };

    const getIcon = (icon: string) => {
        switch (icon) {
            case "settings":
                return <SettingsIcon sx={{ mr: 1 }} />;
            case "logout":
                return <LogoutIcon sx={{ mr: 1 }} />;
            case "download":
                return <DownloadIcon sx={{ mr: 1 }} />;
            default:
                return <></>;
        }
    };

    const getNavMenuDesktop = () => {
        return (
            <>
                <Box sx={{ marginRight: "20px" }} py={0.9} mt={0.2}>
                    <Link to={"/"} style={{ display: "block" }}>
                        <img src={logo} className="App-logo" alt="logo" style={{ width: "auto", height: "45px", maxHeight: "100%" }} />
                    </Link>
                </Box>
                <FilterComponent buttonSx={getNavButtonSx(false, true)} />
                {getMainNavElements(hasPermission).map((navElement: any) => {
                    if (!navElement.subEntries) {
                        return (
                            <Link
                                to={navElement.path}
                                style={{
                                    textDecoration: "none",
                                }}
                                key={"main_nav_" + navElement.translateKeyNavPoint}
                            >
                                <Button sx={getNavButtonSx()}>{t(navElement.translateKeyNavPoint)}</Button>
                            </Link>
                        );
                    } else {
                        return (
                            <React.Fragment key={"main_nav_" + navElement.translateKeyNavPoint}>
                                <PopupState variant="popover" popupId={"popup-menu" + navElement.translateKeyNavPoint}>
                                    {(popupState: any) => (
                                        <>
                                            <Button
                                                {...bindHover(popupState)}
                                                sx={getNavButtonSx()}
                                                disableRipple={true}
                                                endIcon={<KeyboardArrowDownIcon />}
                                            >
                                                {t(navElement.translateKeyNavPoint)}
                                            </Button>
                                            {navElement.subEntries && (
                                                <HoverMenu
                                                    {...bindMenu(popupState)}
                                                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                    sx={submenuSx}
                                                    PaperProps={{
                                                        sx: {
                                                            borderLeft: "3px solid " + theme.palette.primary.main,
                                                            minWidth: popupState && popupState.anchorEl && popupState.anchorEl.offsetWidth,
                                                            padding: 0,
                                                            borderRadius: 0,
                                                        },
                                                    }}
                                                    MenuListProps={{
                                                        className: "appBarSubMenuItem",
                                                        sx: {
                                                            margin: "0 0 0 -3px",
                                                            padding: 0,
                                                        },
                                                    }}
                                                >
                                                    {navElement.subEntries?.map((submenuItem: NavElement) => {
                                                        return (
                                                            <Link
                                                                to={submenuItem.path as string}
                                                                key={"sub_menu_item_" + submenuItem.translateKeyNavPoint}
                                                                onClick={popupState.close}
                                                            >
                                                                <MenuItem className={"appBarSubMenuItem"} onClick={popupState.close}>
                                                                    {t(submenuItem.translateKeyNavPoint)}
                                                                </MenuItem>
                                                            </Link>
                                                        );
                                                    })}
                                                </HoverMenu>
                                            )}
                                        </>
                                    )}
                                </PopupState>
                            </React.Fragment>
                        );
                    }
                })}
            </>
        );
    };

    return (
        <AppBar
            position="static"
            sx={{
                borderBottom: "1px solid " + theme.palette.primary.main,
                boxShadow: "inset 0 -2px 0" + theme.palette.primary.main,
            }}
        >
            <Container>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: "flex" }}>{getNavMenuDesktop()}</Box>
                    <NotificationIconComponent NotificationIconSX={{}}/>
                    <Box sx={{ flexGrow: 0 }}>
                        <PopupState variant="popover" popupId={"popup-menu-settings"}>
                            {(popupState: any) => (
                                <>
                                    <Button
                                        {...bindHover(popupState)}
                                        {...bindFocus(popupState)}
                                        sx={getNavButtonSx(true)}
                                        disableRipple={true}
                                        endIcon={<KeyboardArrowDownIcon />}
                                    >
                                        <UserImageComponent maxWidth={"80px"} />
                                        <div style={{ marginLeft: "25px" }}>{user?.username}</div>
                                    </Button>
                                    <HoverMenu
                                        {...bindMenu(popupState)}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                                        sx={submenuSx}
                                        PaperProps={{
                                            sx: {
                                                borderLeft: "3px solid " + theme.palette.primary.main,
                                                minWidth: popupState && popupState.anchorEl && popupState.anchorEl.offsetWidth,
                                                padding: 0,
                                                borderRadius: 0,
                                            },
                                        }}
                                        MenuListProps={{
                                            className: "appBarSubMenuItem",
                                            sx: {
                                                margin: "0 0 0 -3px",
                                                padding: 0,
                                            },
                                        }}
                                    >
                                        {getSettingsMenuNavElements(hasPermission).map((navElement: NavElement, index: number) => {
                                            return (
                                                <Link
                                                    to={navElement.path as string}
                                                    key={"header_settings_nav_" + index}
                                                    onClick={popupState.close}
                                                >
                                                    <MenuItem className={"appBarSubMenuItem"} onClick={popupState.close}>
                                                        {navElement.icon && getIcon(navElement.icon)}
                                                        {t(navElement.translateKeyNavPoint)}
                                                    </MenuItem>
                                                </Link>
                                            );
                                        })}
                                    </HoverMenu>
                                </>
                            )}
                        </PopupState>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default DesktopHeaderComponent;
