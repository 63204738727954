import {AxiosResponse} from "axios";

export class ApiResponse {

    success: boolean;

    data: any;

    errorMessages?: object;

    constructor(response: AxiosResponse) {
        this.success = response.data.success;
        this.data = response.data.data;
        this.errorMessages = response.data.errorMessages;
    }

    getErrorMessage(fieldName: string): string | null {
        if (this.data.errorMessage && this.data.errorMessage[fieldName]) {
            let firstKey = Object.keys(this.data.errorMessage[fieldName])[0];
            return this.data.errorMessage[fieldName][firstKey];
        }
        return '';
    }
}

/**
 * check are there errorMessages in the response.
 * @param reason
 */
export function getErrorMessagesFromResponse(reason: any) {
    return reason?.response?.errorMessage;
}
